import queryString from "query-string";
// import jwtService from "./jwtService";
import { API_ROOT } from "./apiConfig";

const apiService = {
  setHeaders() {
    const headersConfig = {
      "Content-Type": "application/json",
    };

    // if (jwtService.getToken()) {
    //   headersConfig["Authorization"] = jwtService.getToken();
    // }

    return headersConfig;
  },

  get(path, queryParams) {
    const query = queryParams ? `?${queryString.stringify(queryParams)}` : "";
    return fetch(`${API_ROOT}${path}${query}`, {
      headers: this.setHeaders(),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return Promise.all([res.json(), res.status]).then(([error, status]) => {
        const result = {
          status,
          error,
        };
        throw result;
      });
    });
  },

  delete(path) {
    return fetch(`${API_ROOT}${path}`, {
      method: "DELETE",
      headers: this.setHeaders(),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then((error) => {
        throw error;
      });
    });
  },

  post(path, data) {
    return fetch(`${API_ROOT}${path}`, {
      method: "POST",
      headers: this.setHeaders(),
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then((error) => {
        throw error;
      });
    });
  },

  put(path, data) {
    return fetch(`${API_ROOT}${path}`, {
      method: "PUT",
      headers: this.setHeaders(),
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then((error) => {
        throw error;
      });
    });
  },

  patch(path, data) {
    return fetch(`${API_ROOT}${path}`, {
      method: "PATCH",
      headers: this.setHeaders(),
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then((error) => {
        throw error;
      });
    });
  },
};

export default apiService;
