import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { meanBy } from "lodash";
import { format } from "date-fns";

import aseClient from "../lib/aseClient";

function Ase() {
  const [average200, setAverage200] = useState(null);
  const [last, setLast] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const result = await aseClient.loadGD();
      const avg200 = meanBy(result.Items, "close");
      setAverage200(avg200);
      setLast(result.Items[0]);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Container maxWidth="sm">
      <Box my={4} textAlign="center">
        <Typography variant="h6">Athens Stock Exchange</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              Market is:{" "}
              {average200 > last.close ? (
                <strong>Bear</strong>
              ) : (
                <strong>Bull</strong>
              )}
            </Typography>
            <Typography variant="body2">
              Prices updated at: {format(last.datetime, "yyyy-MM-dd hh:mm")}
            </Typography>
          </>
        )}
      </Box>
    </Container>
  );
}

export default Ase;
