import apiService from "./apiService";

const aseClient = {
  loadGD() {
    return new Promise((resolve, reject) => {
      apiService
        .get("tickers/ase?retrieve=gd")
        .then((res) => {
          //   console.log(res);
          return resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};

export default aseClient;
