let backendHost;

const hostname = window && window.location && window.location.hostname;

if (/vercel.com$/.test(hostname) || /mertacor.com$/.test(hostname)) {
  backendHost = `https://${hostname}`;
} else {
  backendHost = "http://localhost:3000";
}

export const { NODE_ENV } = process.env;
export const BACKEND = backendHost;
export const API_ROOT = `${backendHost}/api/`;
