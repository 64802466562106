import React from "react";
import AppBar from "@material-ui/core/AppBar";
import TimelineIcon from "@material-ui/icons/Timeline";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    // paddingTop: '56.25%', // 16:9
    paddingTop: "80%",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <AppBar position="relative">
      <Toolbar>
        <TimelineIcon className={classes.icon} />
        <Typography variant="h6" color="inherit" noWrap>
          <Link component={RouterLink} to="/" color="inherit" underline="none">
            Mertacor.com
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
